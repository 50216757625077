<template>
    <v-main>
        <Header/>
        <div class="section6">

        <v-row> 
                <v-col>
                    <div class="aurora2">

        <v-img max-height="30em" contain min-height="20em" :src="require('../assets/Resized Pictures/_DSC4768.webp')">
         <v-card-title   style="margin-top:5em;"  primary-title>
             <h1>
             </h1>
         </v-card-title>
         </v-img> 

             </div>
                </v-col>
            </v-row>
            
        <v-container>
           <v-row>
          <v-card-title style="margin-bottom:1em" primary-title>  
                  <h2>
                      Web Design & Development
                  </h2>
            <br>
            </v-card-title>
                   <div class="space">
               <v-col>
                       

           <h2>
              Grow your business and establish a credible online presence.
               </h2>
               <br>
        <p>
            In the information age,
             build a website before you build a workplace.
              Your website is the very first introduction that a prospective client will have to your business. <br>
               When a potential client arrives on your website, it takes a matter of seconds to determine whether they will stay and look further,
                or leave and never return. The Code Effect can make your website stand apart.

        </p>
               </v-col>
                   </div>
           </v-row>
        </v-container>
         <v-container>
            <v-row>
                <v-col>

            <v-card-title>
            
        <!-- <h4 >
            Services
        </h4> -->
            </v-card-title>
        <h3> Before reaching out to us, take a look at the features and benefits of our web development services:</h3>
                </v-col>
            </v-row>
        </v-container>



      <WebCards/>
       
       
       
       
       
        <v-container>
            <v-row>
              <div class="space">
                <v-col>
                    <p>
                          At The Code Effect we deliver premium websites that create the right impression.
                           Stay ahead of your competition with smooth user interface and experience. 
                           Turn potential visitors to clients, and keep them on the site improving the conversion rate. 

                          </p>
                </v-col>
              </div>
            </v-row>
        </v-container>

            <!-- customer benifits -->

            <v-container>
                
 
                    <v-row class="justify-center">

                
                        <v-card-title  style="word-break: normal; margin-top:1.5em;">
                    <h2>
                    Our Web Development services will ensure you:
                    </h2>
                    </v-card-title> 
                <v-col class="justify-center" cols="12" lg="6" md="8" sm="8">
                <!-- <div class="round"> -->
                    <!-- <v-card-text >
            
                        <ul>
                        <li>Create the right impression to your clients<br></li>
                        <li>Improve your conversion rate<br></li>
                        <li>Stay Ahead of Your Competition<br></li>
                        <li>Tailored to business requirement<br></li>
                        </ul>
                        </v-card-text>   
                </div> -->
                <div class="aurora2 justify-center">
                    <v-card-text  >
            
                        <ul >
                            
                        <li>
                            <p>
                       Create the right impression to your clients
                            </p>
                        </li>
                        <li>
                              <p>
                        Improve your conversion rate
                          </p>
                        </li>
                        <li>
                              <p>
                        Stay Ahead of Your Competition
                            </p>
                            </li>
                        </ul>
                        </v-card-text>   
                </div>
                    </v-col>
                    </v-row>
            <!-- </v-row> -->
                <!-- </div> -->
            </v-container>
        <!-- </v-container> -->
        <v-container>
            <v-row class="justify-center"> 
                <v-col>

            
        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        Technologies Used:
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="3" md="3" sm="2" v-for="item in items" :key="item.id">
                    <div class="space" v-tilt="{glare:true, reverse: true}">
                      <v-lazy>

                    <v-img class="icon" contain  max-height="6em" max-width="5em"  :src='item.icon'></v-img>
                      </v-lazy>
                    </div>
                </v-col>
            </v-row>
        </v-container>
                </v-col>
                <v-col>

        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        We're experienced in:
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            
        <!-- </v-container> -->
            <v-row>
                    <div class="space"  >
                <v-col>
                        <ul>
                            <li>
                             <p>
                             E-Learning Web Applications 
                             </p>
                              </li>
                            <li>
                                <p>
                                E-Commerce Website Development
                                </p>
                            </li>
                            <li>
                                <p>
                                Landing Pages
                                </p>
                            </li>
                            <li>
                                <p>
                                Search Engine Optimization (SEO)
                                </p>
                            </li>
                            <li>
                                <p>
                                Back-end Development
                                </p>
                            </li>
                            <li>
                                <p>
                                API Development and Integration
                                </p>
                            </li>
                            <li>
                                <p>
                                Software Architecture
                                </p>
                            </li>
                                                       
                        </ul>
                </v-col>
                    </div>
            </v-row>
        </v-container>

                </v-col>
    </v-row>
        </v-container>



         <Footer/>   
        </div>

    </v-main>
</template>
<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import WebCards from '../components/WebCards.vue'
export default {
  components: { WebCards, Header,Footer },
    name: 'System',
    data() {
        return {
            items:[
          {
            id:1,
            icon: require('../assets/icons/HTML.svg'),
            // color:'orange darken-2'
          },
          {
            id:2,
            icon: require('../assets/icons/CSS.svg'),
            color:'blue-grey darken-2'
          },
          {
            id:3,
            icon: require('../assets/icons/JS.svg'),
            color:'orange darken-2'
          },
          {
            id:4,
            icon: require('../assets/icons/Flutter.svg'),
            color:'teal'
          },
          {
            id:5,
            icon: require('../assets/icons/SASS.svg'),
            color:'primary'
          },
          {
            id:6,
            icon: require('../assets/icons/Vue.svg'),
            color:'primary'
          },
          {
            id:7,
            icon: require('../assets/icons/vuetifyjs.svg'),
            color:'primary'
          },
          {
            id:8,
            icon: require('../assets/icons/bootstrap.svg'),
            color:'primary'
          },
          {
            id:9,
            icon: require('../assets/icons/TS.svg'),
            color:'primary'
          },
          {
            id:10,
            icon: require('../assets/icons/Laravel.svg'),
            color:'primary'
          },
          {
            id:11,
            icon: require('../assets/icons/nodejs.svg'),
            color:'primary'
          },
          {
            id:12,
            icon: require('../assets/icons/wordpress.svg'),
            color:'primary'
          },
          {
            id:13,
            icon: require('../assets/icons/PHP.svg'),
            color:'primary'
          },
          {
            id:14,
            icon: require('../assets/icons/MySQL.svg'),
            color:'primary'
          },
          {
            id:15,
            icon: require('../assets/icons/postgreSQL.svg'),
            color:'primary'
          },
          {
            id:16,
            icon: require('../assets/icons/spring.svg'),
            color:'primary'
          },
          {
            id:17,
            icon: require('../assets/icons/dart.svg'),
            color:'primary'
          },
          {
            id:18,
            icon: require('../assets/icons/redis.svg'),
            color:'primary'
          },
          {
            id:19,
            icon: require('../assets/icons/java.svg'),
            color:'primary'
          }
        ]
            
        }
    },
    
 
    
}
</script>
<style lang="postcss">
/* List */
 @import '../sass/list.sass'
</style>